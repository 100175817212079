<template>
    <!-- 头寸管理 positionManagement -->
    <div class="container">
        <div class="header" v-if="isMobile==0">
            <router-link class="btn" to="/stocks">{{$t('aside.list')[1]}}</router-link>
            <i class="iconfont2 icon-right_arrow"></i>
            <span>{{$t('stocks.positionManagement')}}</span>
        </div>

        <div class="content" :class="{content_phone:isMobile==1}">
            <div class="dataSummaryBox">
                <div class="label">{{$t('stocks.totalPL')}}</div>
                <div class="data">19999999378728732899</div>
            </div>

            <div class="title">
                <i class="iconfont2 icon-shaixuan"></i>
                {{$t('stocks.positionList')}}
            </div>
            <div class="listBox">
                <div class="list1">
                    <el-table
                        stripe
                        max-height="300"
                        :data="tableList1">
                        <el-table-column
                        header-align="center"
                        align="center"
                        prop="web"
                        :label="$t('common.exchange')"
                        min-width="100">
                        </el-table-column>
                        <el-table-column
                        header-align="center"
                        align="center"
                        prop="currency_name"
                        :label="$t('stocks.symbol')"
                        min-width="100">
                        </el-table-column>
                        <el-table-column
                        class-name="column"
                        header-align="center"
                        align="center"
                        prop="direction"
                        :label="$t('common.direction')"
                        min-width="80">
                            <template slot-scope="scope">
                                <span v-if="scope.row.direction=='long'" class="g_green">{{ $t("stocks.goLong") }}</span>
                                <span v-else-if="scope.row.direction=='short'" class="g_red">{{ $t("stocks.goShort") }}</span>
                                <div class="g_btn tag">{{scope.row.leverage}}.00x</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        header-align="center"
                        align="center"
                        prop="total"
                        :label="$t('stocks.positionQuantity')"
                        min-width="100">
                        </el-table-column>
                        <el-table-column
                        header-align="center"
                        align="center"
                        prop="balance"
                        :label="$t('stocks.liquidatableQuantity')"
                        min-width="100">
                        </el-table-column>
                        <el-table-column
                        header-align="center"
                        align="center"
                        prop="total_usd"
                        :label="$t('stocks.positionAmount')"
                        min-width="100">
                        </el-table-column>
                        <el-table-column
                        header-align="center"
                        align="center"
                        prop="price"
                        :label="$t('stocks.averagePositionPrice')"
                        min-width="100">
                        </el-table-column>
                        <el-table-column
                        header-align="center"
                        align="center"
                        prop="profit"
                        :label="$t('stocks.unrealizedProfit')"
                        min-width="100">
                            <template slot-scope="scope">
                                <span :class="scope.row.profit<0?'g_red':'g_green'">
                                    {{ scope.row.profit }}
                                    <!-- {{yenUnit2.toUpperCase()}} -->
                                </span>
                            </template>
                        </el-table-column>
                        
                        <el-empty slot="empty"
                        :image="emptyImg"
                        :image-size="120"
                        :description="$t('common.noData')"
                        />
                    </el-table>
                </div>
                <div class="list2">
                    <el-table
                        stripe
                        max-height="300"
                        :data="tableList2">
                        <el-table-column
                        header-align="center"
                        align="center"
                        prop="web"
                        :label="$t('common.exchange')"
                        min-width="100">
                        </el-table-column>
                        <el-table-column
                        header-align="center"
                        align="center"
                        prop="currency_name"
                        :label="$t('stocks.symbol')"
                        min-width="100">
                        </el-table-column>
                        <el-table-column
                        class-name="column"
                        header-align="center"
                        align="center"
                        prop="direction"
                        :label="$t('common.direction')"
                        min-width="80">
                            <template slot-scope="scope">
                                <span v-if="scope.row.direction=='long'" class="g_green">{{ $t("stocks.goLong") }}</span>
                                <span v-else-if="scope.row.direction=='short'" class="g_red">{{ $t("stocks.goShort") }}</span>
                                <div class="g_btn tag">{{scope.row.leverage}}.00x</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        header-align="center"
                        align="center"
                        prop="total"
                        :label="$t('stocks.positionQuantity')"
                        min-width="100">
                        </el-table-column>
                        <el-table-column
                        header-align="center"
                        align="center"
                        prop="balance"
                        :label="$t('stocks.liquidatableQuantity')"
                        min-width="100">
                        </el-table-column>
                        <el-table-column
                        header-align="center"
                        align="center"
                        prop="total_usd"
                        :label="$t('stocks.positionAmount')"
                        min-width="100">
                        </el-table-column>
                        <el-table-column
                        header-align="center"
                        align="center"
                        prop="price"
                        :label="$t('stocks.averagePositionPrice')"
                        min-width="100">
                        </el-table-column>
                        <el-table-column
                        header-align="center"
                        align="center"
                        prop="profit"
                        :label="$t('stocks.unrealizedProfit')"
                        min-width="100">
                            <template slot-scope="scope">
                                <span :class="scope.row.profit<0?'g_red':'g_green'">
                                    {{ scope.row.profit }}
                                    <!-- {{yenUnit2.toUpperCase()}} -->
                                </span>
                            </template>
                        </el-table-column>
                        
                        <el-empty slot="empty"
                        :image="emptyImg"
                        :image-size="120"
                        :description="$t('common.noData')"
                        />
                    </el-table>
                </div>
            </div>

            <div class="title">
                <i class="iconfont2 icon-shaixuan"></i>
                {{$t('stocks.positionData')}}
            </div>
            <div class="tableBox">
                <el-table
                    stripe
                    max-height="300"
                    :data="tableList3">
                    <el-table-column
                    sortable
                    header-align="center"
                    align="center"
                    prop="web"
                    :label="$t('common.exchange')"
                    min-width="100">
                    </el-table-column>
                    <el-table-column
                    sortable
                    header-align="center"
                    align="center"
                    prop="currency_name"
                    :label="$t('stocks.symbol')"
                    min-width="100">
                    </el-table-column>
                    <el-table-column
                    sortable
                    header-align="center"
                    align="center"
                    prop="longPosition"
                    :label="$t('stocks.longPosition')+' ('+$t('common.contracts')+')'"
                    min-width="100">
                    </el-table-column>
                    <el-table-column
                    sortable
                    header-align="center"
                    align="center"
                    prop="shortPosition"
                    :label="$t('stocks.shortPosition')+' ('+$t('common.contracts')+')'"
                    min-width="100">
                    </el-table-column>
                    <el-table-column
                    sortable
                    header-align="center"
                    align="center"
                    prop="totalPosition"
                    :label="$t('stocks.totalPosition')+' ('+$t('common.contracts')+')'"
                    min-width="100">
                    </el-table-column>
                    <el-table-column
                    sortable
                    header-align="center"
                    align="center"
                    prop="netPosition"
                    :label="$t('stocks.netPosition')"
                    min-width="100">
                    </el-table-column>
                    
                    <el-table-column
                    sortable
                    header-align="center"
                    align="center"
                    prop="longPL"
                    :label="$t('stocks.longPL')"
                    min-width="100">
                        <template slot-scope="scope">
                            <span :class="scope.row.longPL<0?'g_red':'g_green'">
                                {{ scope.row.longPL }}
                                <!-- {{yenUnit2.toUpperCase()}} -->
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                    sortable
                    header-align="center"
                    align="center"
                    prop="shortPL"
                    :label="$t('stocks.shortPL')"
                    min-width="100">
                        <template slot-scope="scope">
                            <span :class="scope.row.shortPL<0?'g_red':'g_green'">
                                {{ scope.row.shortPL }}
                                <!-- {{yenUnit2.toUpperCase()}} -->
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                    sortable
                    header-align="center"
                    align="center"
                    prop="totalPL"
                    :label="$t('stocks.totalPL')"
                    min-width="100">
                        <template slot-scope="scope">
                            <span :class="scope.row.totalPL<0?'g_red':'g_green'">
                                {{ scope.row.totalPL }}
                                <!-- {{yenUnit2.toUpperCase()}} -->
                            </span>
                        </template>
                    </el-table-column>
                    
                    <el-empty slot="empty"
                    :image="emptyImg"
                    :image-size="120"
                    :description="$t('common.noData')"
                    />
                </el-table>
            </div>
        </div>
    </div>
</template>
<style lang="stylus" scoped>
@import 'positionManagement.styl';
</style>
<script>
import { mapGetters } from 'vuex';
import { Loading } from 'element-ui';
import Decimal from 'decimal.js'
// api
import {
  futurePositionManagement,
} from "@/api/markets";
export default {
    computed:{
        ...mapGetters(['account','isMobile']),
    },
    data() {
        return {
            emptyImg: require("@/assets/images/noorder.png"),
            loadingSerive: null,
            
            tableList1: [],
            tableList2: [],
            tableList3: [],
        }
    },
    created(){
        this.getList();
    },
    methods: {
        getList(){
            this.loadingSerive = Loading.service({
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                customClass: 'apploading',
            });
            futurePositionManagement({
                account: this.account,
            }).then(res=>{
                this.loadingSerive.close();
                // console.log(res);
                
                var tableList1=[],
                    tableList2=[],
                    tableObjList3={},
                    tableList3=[]
                for(var webItem in res){
                    // console.log(webItem,res[webItem])
                    
                    if(!!res[webItem]&&Object.keys(res[webItem]).length>0){
                        for(var pairItem in res[webItem]){
                            // console.log(pairItem,res[webItem][pairItem])
                            
                            if(!!res[webItem][pairItem]){
                                res[webItem][pairItem].forEach(row => {
                                    var obj={...row};
                                    obj.web=webItem;
                                    var longPosition=0,
                                        shortPosition=0,
                                        longProfit=0,
                                        shortProfit=0;

                                    if(obj.direction=='long'){
                                        tableList1.push(obj);
                                        longPosition=obj.total;
                                        longProfit=obj.profit;
                                    }else if(obj.direction=='short'){
                                        tableList2.push(obj);
                                        shortPosition=obj.total;
                                        shortProfit=obj.profit;
                                    }

                                    if(tableObjList3[webItem+pairItem]){
                                        tableObjList3[webItem+pairItem].longPosition=new Decimal(tableObjList3[webItem+pairItem].longPosition).add(new Decimal(longPosition)).toString();
                                        tableObjList3[webItem+pairItem].shortPosition=new Decimal(tableObjList3[webItem+pairItem].shortPosition).add(new Decimal(shortPosition)).toString();
                                        tableObjList3[webItem+pairItem].totalPosition=new Decimal(tableObjList3[webItem+pairItem].longPosition).add(new Decimal(tableObjList3[webItem+pairItem].shortPosition)).toString();
                                        tableObjList3[webItem+pairItem].netPosition=Math.abs(Number(new Decimal(tableObjList3[webItem+pairItem].longPosition).sub(new Decimal(tableObjList3[webItem+pairItem].shortPosition))));

                                        tableObjList3[webItem+pairItem].longPL=new Decimal(tableObjList3[webItem+pairItem].longPL).add(new Decimal(longProfit)).toString();
                                        tableObjList3[webItem+pairItem].shortPL=new Decimal(tableObjList3[webItem+pairItem].shortPL).add(new Decimal(shortProfit)).toString();
                                        tableObjList3[webItem+pairItem].totalPL=new Decimal(tableObjList3[webItem+pairItem].longPL).add(new Decimal(tableObjList3[webItem+pairItem].shortPL)).toString();
                                    }else{
                                        tableObjList3[webItem+pairItem]={
                                            web:webItem,
                                            currency_name:row.currency_name,
                                            longPosition:longPosition,
                                            shortPosition:shortPosition,
                                            totalPosition:new Decimal(longPosition).add(new Decimal(shortPosition)).toString(),
                                            netPosition:Math.abs(Number(new Decimal(longPosition).sub(new Decimal(shortPosition)))),
                                            longPL:longProfit,
                                            shortPL:shortProfit,
                                            totalPL:new Decimal(longProfit).add(new Decimal(shortProfit)).toString(),
                                        }
                                    }
                                    
                                });
                            }
                        }
                    }
                }
                this.tableList1=tableList1;
                this.tableList2=tableList2;

                this.tableList3=Object.values(tableObjList3);
                // console.log(this.tableList1,this.tableList2,this.tableList3);
            }).catch(err=>{
                this.loadingSerive.close();
            })
        },
    }
}
</script>